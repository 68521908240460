import React from "react";

//Social Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

//Assets
import IGTLogo from "../assets/igt_logo_rev.png";
import Malta from "../assets/mga_logo.png";
import "./Footer_Stylesheet.css"

//Styles
import { FooterContainer } from "./Footer.styles";
import { Container } from "reactstrap";

const Footer = (props) => {
    return (
        <div className= "FooterStyle">
            <footer className="FooterContainer">
          <div className="FooterLogo">
              <img src={IGTLogo} alt="IGT Brand" />
          </div>
        <div className="FooterContent">
            <h1>
                <a
                href="https://www.igt.com/explore-igt/about-igt/global-responsibility"
                target="_blank"
                rel="noopener noreferrer"
                title="Global Responsibility"
                >
                    Global Responsibility
                </a>
            </h1>
            <p>
                  IGT is committed to operating its business with the goal of
                  creating value for all our stakeholders. Our sustainability
                  strategy focuses on social, environmental, and economic
                  responsibility.
            </p>
            <a
                href="https://www.authorisation.mga.org.mt/verification.aspx?lang=EN&amp;company=e5188f40-f9ac-46d3-9cf8-4708c80bc535&amp;details=1"
                target="_blank"
                rel="noopener noreferrer"
                >
                <img alt="Malta" src={Malta}/>
            </a>
              <p style={{
                  color: "#fff",
                  margin: "0",
                  fontSize: "10px",
                  lineHeight: "12px",
              }}>
                  International Game Technology PLC, a public limited
                  company organized under the laws of England and Wales,
                  has its corporate headquarters at 66 Seymour Street, 2nd
                  floor, London, W1H 5BT, United Kingdom. International
                  Game Technology PLC, together with its consolidated
                  subsidiaries, has principal operating facilities in
                  Providence, Rhode Island; Las Vegas, Nevada; and Rome,
                  Italy. IGT Malta Casino Limited is licensed by the Malta
                  Gaming Authority under a B2B Critical Supply Licence to
                  provide games to B2C operators in the European Union or
                  European Economic Area. IGT Malta Casino Limited is
                  licensed and regulated by the Malta Gaming Authority,
                  holding the license number MGA/B2B/168/2007 issued on
                  1st August 2018. Its registration number is C 40930. The
                  registered office is located at IGT Malta Casino
                  Limited, 2 Belvedere Court, Triq il-Qaliet, St. Julians
                  STJ 3255, tel: +356 21388366.
            </p>
            <ul>
            <li>
            <a
            href="https://www.igt.com/-/media/7d13e6a0f5c14eb992ad305660e46e29.ashx"
            title="Modern Slavery Act"
            target="_blank"
            rel="noopener noreferrer"
            >
            Modern Slavery Act
            </a>
            </li>
            <li>
            <a
            href="https://www.igt.com/en/privacy-notice"
            title="Privacy Notice"
            target="_blank"
            rel="noopener noreferrer"
            >
            Privacy Notice
            </a>
            </li>
            <li>
            <a
            href="https://www.igt.com/en/terms-of-use"
            title=""
            target="_blank"
            rel="noopener noreferrer"
            >
            Terms of Use
            </a>
            </li>
            <li>
            <a
            href="https://www.igt.com/en/trademarks"
            title="Trademarks"
            target="_blank"
            rel="noopener noreferrer"
            >
            Trademarks
            </a>
            </li>
                  </ul>

         
        <hr />
        <span>
            &copy; 2020 IGT. All rights reserved.
        </span>
              <ul style={{ float: "right" }}>
            <li>
            <a
            href="http://www.facebook.com/igt"
            title="Facebook"
            target="_blank"
            rel="noopener noreferrer"
            >
            <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
            </li>
            <li>
            <a
            href="http://www.linkedin.com/company/igt"
            title="Linkedin"
            target="_blank"
            rel="noopener noreferrer"
            >
            <FontAwesomeIcon icon={faLinkedin} />
            </a>
            </li>
            <li>
            <a
            href="http://www.twitter.com/igtnews"
            title="Twitter"
            target="_blank"
            rel="noopener noreferrer"
            >
            <FontAwesomeIcon icon={faTwitter} />
            </a>
            </li>
            <li>
            <a
            href="http://www.youtube.com/igt"
            title="YouTube"
            target="_blank"
            rel="noopener noreferrer"
            >
            <FontAwesomeIcon icon={faYoutube} />
            </a>
            </li>
              </ul>
          </div>
          <br/>
            </footer>
        </div>
  );
};

export default Footer;


//old footer code
{/*
          <div className="row">
            <div className="col-12">
              <div className="footer_top clearfix">
                <div className="footer_res">
                  <h3>
                    <a
                      href="https://www.igt.com/explore-igt/about-igt/global-responsibility"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Global Responsibility"
                    >
                      Global Responsibility
                    </a>
                  </h3>
                  <p>
                    IGT is committed to operating its business with the goal of
                    creating value for all our stakeholders. Our sustainability
                    strategy focuses on social, environmental, and economic
                    responsibility.
                  </p>

                  <div className="row">
                    <div className="pt-3">
                      <a
                        href="https://www.authorisation.mga.org.mt/verification.aspx?lang=EN&amp;company=e5188f40-f9ac-46d3-9cf8-4708c80bc535&amp;details=1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ width: "60px", margin: "20px 0 4px" }}
                          alt="Malta"
                          src={Malta}
                        />
                      </a>
                      <small
                        className="malta_small"
                        style={{
                          color: "#fff",
                          margin: "0",
                          fontSize: "10px",
                          lineHeight: "12px",
                        }}
                      >
                        International Game Technology PLC, a public limited
                        company organized under the laws of England and Wales,
                        has its corporate headquarters at 66 Seymour Street, 2nd
                        floor, London, W1H 5BT, United Kingdom. International
                        Game Technology PLC, together with its consolidated
                        subsidiaries, has principal operating facilities in
                        Providence, Rhode Island; Las Vegas, Nevada; and Rome,
                        Italy. IGT Malta Casino Limited is licensed by the Malta
                        Gaming Authority under a B2B Critical Supply Licence to
                        provide games to B2C operators in the European Union or
                        European Economic Area. IGT Malta Casino Limited is
                        licensed and regulated by the Malta Gaming Authority,
                        holding the license number MGA/B2B/168/2007 issued on
                        1st August 2018. Its registration number is C 40930. The
                        registered office is located at IGT Malta Casino
                        Limited, 2 Belvedere Court, Triq il-Qaliet, St. Julians
                        STJ 3255, tel: +356 21388366.
                      </small>
                    </div>
                  </div>
                </div>
                <div className="footer_logo">
                  <img src={IGTLogo} alt="IGT Brand" />
                </div>
              </div>
              <div className="footer_mid clearfix">
                <ul className="text_links">
                  <li style={{ display: "inline", float: "left" }}>
                    <a
                      href="https://www.igt.com/-/media/7d13e6a0f5c14eb992ad305660e46e29.ashx"
                      title="Modern Slavery Act"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Modern Slavery Act
                    </a>
                  </li>
                  <li style={{ display: "inline", float: "left" }}>
                    <a
                      href="https://www.igt.com/en/privacy-notice"
                      title="Privacy Notice"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Notice
                    </a>
                  </li>
                  <li style={{ display: "inline", float: "left" }}>
                    <a
                      href="https://www.igt.com/en/terms-of-use"
                      title=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms of Use
                    </a>
                  </li>
                  <li style={{ display: "inline", float: "left" }}>
                    <a
                      href="https://www.igt.com/en/trademarks"
                      title="Trademarks"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Trademarks
                    </a>
                  </li>
                </ul>
              </div>
              <hr />
              <div className="footer_bot clearfix">
                <p className="igt_copyright">
                  &copy; <span id="FooterYear">2020</span> IGT. All rights
                  reserved.
                </p>

                <ul className="icon_links">
                  <li>
                    <a
                      href="http://www.facebook.com/igt"
                      title="Facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.linkedin.com/company/igt"
                      title="Linkedin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.twitter.com/igtnews"
                      title="Twitter"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.youtube.com/igt"
                      title="YouTube"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          */}
