export const breakpoints = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '525px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
};

export const MasterTheme = {
    //Dominant Colors
    brandWhite: '#fafafa',
    igtBlue: '#003862',
    igtBlueDark: '#0D51A1',
    // igtBlueLight: '#91c7e6',
    igtBlueLight: '#1b9ddb',
    igtYellow: '#ffdc00',
    igtDarkOrange: '#ff682e',
    // Supporting Colors
    igtGray: '#586064',
    igtLightGray: '#bebfbd',
    igtBlack: '#020202',
    igtBlueOpaque: '#e8f6fc',

    //Corporate Colors
    igtMediumBlue: '#159cdb',
    igtOrange: '#ff902e',
    //Misc Colors
    warningRed: '#ff682e',
    successGreen: '#4DAA57',
    altBlueBg: '#e6f2ff',

    //Box Rad
    boxRadius: '2px',
    //Box Shadow
    boxShadow: '1px 1px 1px #595959',
    boxShadowInset: '0px 0px 6px inset #bebfbd',
    altBoxShadow: '1px 1px 1px #bebfbd',
    gameTableBoxShadow: '1px 1px 3px #b3b4b1',
    //Typeograhy
    baseColor: '#020202',
    mainFont: '',
    secondaryFont: '',

    //Light Hover BG Color
    hoverBgColor: '#f2f3f2',

    //Paginate Number Select
    pagNumberActive: '#e8e8e8',

    //Inputs
    inputMainBg: '#fafafa',

    //DEVICE
    mobileS: `(max-width: ${breakpoints.mobileS})`,
    mobileM: `(max-width: ${breakpoints.mobileM})`,
    mobileL: `(max-width: ${breakpoints.mobileL})`,
    tablet: `(max-width: ${breakpoints.tablet})`,
    laptop: `(max-width: ${breakpoints.laptop})`,
    laptopL: `(max-width: ${breakpoints.laptopL})`,
    desktop: `(max-width: ${breakpoints.desktop})`,
    desktopL: `(max-width: ${breakpoints.desktop})`
};
