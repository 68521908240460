import * as React from "react";

const ResultsCount = (props: any) => {
  return (
    <p
      style={{
        textAlign: "right",
        color: "#0c51a1",
        fontWeight: 700,
        ...props.pStyle,
      }}
    >
      {props.count} Results
    </p>
  );
};

export default ResultsCount;
