import styled from "styled-components";

export const LoaderText = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  text-align: center;
  top: 0;
  font-weight: 300;
  color: grey;
`;
